import React from 'react'
import { Helmet } from "react-helmet"

const SEO = () => {
    return (
        <div>
            <Helmet>
                <title>Ekko Consulting</title>
                <meta name="description" content="" />
                <meta name="og:title" property="og:title" content=""></meta>
                <meta name="twitter:card" content=""></meta>
                <link rel="canonical" href=""></link>
                <meta property="og:image" content="" />
            </Helmet>
        </div>
    )
}

export default SEO
